<template>
    <div class="page-wrapper page-home">
        <!-- Nav Heading Spacer -->
        <div class="header-spacer"></div>

        <!-- Evenements hero -->
        <section
            class="evenements-hero"
            v-if="contentIsLoaded"
            :class="'theme-' + (contentIsLoaded ? content.page.couleurDetails.value : '')"
        >
            <div class="top site-max-width">
                <h2 class="big-title title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.page.titres.titre1"></span>
                </h2>
                <h3 class="small-title float-label" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.titres.titre2"></span>
                </h3>
            </div>

            <div class="img-wrap" data-inview="revealLeft" data-delay="500">
                <img class="main" :src="content.page.imageSimple2" :alt="content.page.title" />
            </div>

            <div class="bottom site-max-width">
                <h2 class="medium-title" data-inview="fadeInUp" data-delay="200">
                    {{ content.page.entete.titre }}
                </h2>
                <p class="text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.entete.texte"></span>
                </p>
            </div>
            <div class="forfaits site-max-width" v-if="content.page.forfaits && content.page.forfaits.length">
                <h2 class="medium-title" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Nos forfaits') }}
                </h2>
                <div class="row" v-for="(item, i) in content.page.forfaits" :key="i">
                    <div class="col">
                        <h3 class="regular-title">{{ item.titreGauche }}</h3>
                        <div class="text">
                            <span v-html="item.texteGauche"></span>
                        </div>
                    </div>
                    <div class="col">
                        <h3 class="regular-title">{{ item.titreDroite }}</h3>
                        <div class="text">
                            <span v-html="item.texteDroite"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Services -->
        <section class="services" v-if="pageIsLoaded">
            <div
                class="bottom site-max-width"
                :class="'theme-' + content.page.couleurDetails.value"
            >
                <div class="cols">
                    <div class="col" v-for="(item, i) in content.page.listeServices" :key="i">
                        <img :src="item.image.url" :alt="item.image.titre" class="small-title" data-inview="fadeInUp" data-delay="200">
                        <h4 class="small-title" data-inview="fadeInUp" data-delay="400">
                            {{ item.titre }}
                        </h4>
                    </div>
                </div>

                <div class="bottom">
                    <h2 class="medium-title" data-inview="fadeInUp" data-delay="200">
                        {{ content.page.titreEtTexte.titre }}
                    </h2>
                    <p class="text" data-inview="fadeInUp" data-delay="400">
                        <span v-html="content.page.titreEtTexte.texte"></span>
                    </p>
                </div>
            </div>
        </section>

        <!-- Menu -->
        <section class="menu-component" v-if="pageIsLoaded && menusMainNav">
            <header class="menu-header" pin-follow>
                <div class="top" data-inview="fadeInUp" data-delay="200" :class="'theme-' + content.page.couleurMenu.value">
                    <h3 class="medium-title">
                        {{ content.page.intro.titre }}
                        <small>{{ content.page.intro.texte }}</small>
                    </h3>
                    <a
                        :href="content.page.intro.boutonUrl"
                        class="site-btn outline"
                        :title="content.page.intro.bouton.customText"
                        >{{ content.page.intro.bouton.customText }}</a
                    >
                </div>

                <nav class="menu-nav site-max-width" :class="'theme-' + content.page.couleurMenu.value">
                    <a
                        href="#"
                        class="nav-link"
                        :class="{ 'is-active': selectedNav === item.id }"
                        v-for="item in menusMainNav"
                        :key="item.id"
                        @click.prevent="selectedNav = item.id"
                    >
                        {{ item.titre }}
                    </a>
                </nav>

                <transition name="fade" mode="out-in">
                    <nav class="menu-nav menu-nav-sub site-max-width" v-if="selectedNav">
                        <a
                            href="#"
                            class="nav-link"
                            :class="{ 'is-active': selectedSubNav === item.id }"
                            v-for="item in menusSubNav"
                            :key="item.id"
                            @click.prevent="selectedSubNav = item.id"
                        >
                            {{ item.titre }}
                        </a>
                    </nav>
                </transition>
            </header>

            <transition name="fade" mode="out-in">
                <article class="menu-content" v-if="selectedMenu">
                    <!-- <aside class="menu-aside">
                        <h4 class="big-title" :class="'theme-' + content.page.couleurMenu.value">
                            MENU
                        </h4>
                    </aside> -->

                    <div class="content" :class="'theme-' + content.page.couleurMenu.value">
                        <span v-html="selectedMenu.page.contenuWysiwyg"></span>
                    </div>
                </article>
            </transition>
        </section>

        <!-- Specs -->
        <section class="techspecs site-max-width" id="techspecs" v-if="pageIsLoaded">
            <div class="title-wrap">
                <h2 class="main-title medium-title" data-inview="fadeInUp" data-delay="200">
                    {{ content.page.entete2.titre }}
                </h2>
                <p class="text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.entete2.texte"></span>
                </p>
                <a
                    :href="content.page.lienSimple.boutonUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="site-btn"
                    data-inview="fadeInUp"
                    data-delay="400"
                    ><p>{{ content.page.lienSimple.bouton.customText }}</p></a
                >
            </div>

            <div class="carrousel-menu">
                <button
                    :class="{ active: position == i }"
                    @click="goTo(i)"
                    v-for="(item, i) in content.page.carousel"
                    :key="i"
                >
                    {{ item.titreNavigation }}
                </button>
            </div>

            <div class="carousel-tech">
                <div
                    class="carousel-cell"
                    v-for="(item, i) in content.page.carousel"
                    :key="'cs-' + i"
                >
                    <div class="img" data-inview="revealRightDark" data-delay="200">
                        <img :src="item.image.url" :alt="item.image.titre" />
                    </div>
                    <div class="data" data-inview="slideLeft" data-delay="400">
                        <h3 class="main-title small small-title">{{ item.titre }}</h3>
                        <div>
                            <p><span v-html="item.texte"></span></p>
                            <a v-if="item.boutonUrl && item.boutonUrl !== '#'" :href="item.boutonUrl" class="site-btn">{{
                                item.bouton.customText
                            }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Events -->
        <EvenementsListing v-if="pageIsLoaded" />
    </div>
</template>

<script>
// @ is an alias to /src
import Flickity from 'flickity'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import EvenementsListing from '@/components/EvenementsListing'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'Evenements',

    data() {
        return {
            carousel: null,
            position: 0,
            flickityOptions: {
                prevNextButtons: false,
                pageDots: false,
                wrapAround: true,
            },
            menusItems: null,
            menusMainNav: null,
            menusSubNav: null,
            selectedNav: null,
            selectedSubNav: null,
        }
    },

    components: {
        EvenementsListing,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.getEventMenus()
                }
            },
            immediate: true,
        },
        menusAreLoaded: {
            handler(menusAreLoaded) {
                if (menusAreLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        const carousel = document.querySelector('.carousel-tech')
                        this.carousel = new Flickity(carousel, this.flickityOptions)
                        this.carousel.on('change', () => {
                            this.position = this.carousel.selectedIndex
                        })

                        const $img = document.querySelector('.main')
                        gsap.to($img, {
                            duration: 60,
                            scale: '1',
                            ease: 'none',
                        })

                        if (!$img) {
                            gsap.to('[pin-follow]', {
                                scrollTrigger: {
                                    trigger: '[pin-follow]',
                                    start: 'top top',
                                    end: () => "+=200%",
                                    scrub: 0,
                                    pin: true,
                                    pinSpacer: false,
                                    pinSpacing: false,
                                    anticipatePin: 0,
                                },
                            });
                        }

                        initInViewAnimations()

                        setTimeout(() => {
                            this.carousel.resize()
                        }, 2000)
                    }, 475)
                }
            },
            immediate: true,
        },
        selectedNav: {
            handler(selectedNav) {
                if (selectedNav) {
                    // Page loaded with Data
                    const obj = {}
                    this.menusItems.forEach((element, index) => {
                        if (element.page.categorieMenu[0].id === parseInt(selectedNav)) {
                            obj[element.page.sousCategorieMenu[0].id] = {
                                'titre': element.page.sousCategorieMenu[0].titre,
                                'order': index
                            }
                        }
                    })
                    const menusSubNav = []
                    for (const [key, value] of Object.entries(obj)) {
                        menusSubNav.push({
                            id: key,
                            titre: value.titre,
                            order: value.order,
                        })
                    }
                    this.menusSubNav = menusSubNav.sort((a, b) => {
                        if (a.order < b.order) {
                            return -1
                        }
                        if (a.order > b.order) {
                            return 1
                        }
                        return 0
                    })
                    this.selectedSubNav = this.menusSubNav[0].id
                }
            },
            immediate: true,
        },
    },

    methods: {
        async getEventMenus() {
            const locale = this.$route.meta.lang === 'fr' ? '' : '/en'
            const baseUrl = '/api'
            await axios
                .get(
                    `${process.env.VUE_APP_SITE_BASE_URL + locale + baseUrl}/menusEntry/${
                        this.content.page.id
                    }.json`
                )
                .then(res => {
                    this.menusItems = res.data.data
                    const obj = {}
                    this.menusItems.forEach((element, index) => {
                        obj[element.page.categorieMenu[0].id] = {
                            'titre': element.page.categorieMenu[0].titre,
                            'order': index
                        }
                    })
                    const menusMainNav = []
                    for (const [key, value] of Object.entries(obj)) {
                        menusMainNav.push({
                            id: key,
                            titre: value.titre,
                            order: value.order,
                        })
                    }
                    this.menusMainNav = menusMainNav.sort((a, b) => {
                        if (a.order < b.order) {
                            return -1
                        }
                        if (a.order > b.order) {
                            return 1
                        }
                        return 0
                    })

                    this.selectedNav = menusMainNav[0].id
                })
                .catch(() => {})
        },
        goTo(pos) {
            this.carousel.select(pos)
        },
    },

    computed: {
        selectedMenu() {
            if (!this.pageIsLoaded || !this.menusAreLoaded) {
                return
            }

            if (!this.selectedNav || !this.selectedSubNav) {
                return
            }

            return this.menusItems.find(
                i =>
                    i.page.categorieMenu[0].id === parseInt(this.selectedNav) &&
                    i.page.sousCategorieMenu[0].id === parseInt(this.selectedSubNav)
            )
        },
        menusAreLoaded() {
            return this.menusItems
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
