<template>
    <section class="events-grid site-max-width">
        <div class="top">
            <h2 class="big-title" data-inview="fadeInUp" data-delay="400">{{ $t('Événements') }}</h2>
        </div>
        <div class="grid-items">
            <router-link
                :to="item.page.jsonUrl"
                v-for="(item, i) in listOfEvents"
                :key="i"
                class="grid-item"
                :class="'theme-' + item.page.couleurTitre.value"
                data-inview="fadeInUp"
                :data-delay="(i + 1) * 2 + '00'"
            >
                <h3 class="title color-2" :class="'theme-' + item.page.couleurTitre.value">
                    <span v-html="item.page.title"></span>
                </h3>
            </router-link>
        </div>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'EvenementsListing',

    computed: {
        listOfEvents() {
            if (!this.pageIsLoaded || !this.$route.path) {
                return []
            }

            return this.globals.evenementsEntry.data.filter(i => !this.$route.path.includes(i.page.jsonUrl))
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
